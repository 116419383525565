<template>
  <div class="login-wrap">
    <div class="i-login-back-head">
      <div class="i-back-head-img"></div>
      <span class="i-back-head-text">密码登录</span>
      <div @click="getWechatQrcodeUrl" class="i-back-head-right-text .ellipsis" style="color: rgb(122, 128, 135)">注册</div>
    </div>
    <div class="login-content">
        <div class="ant-row ant-form-item">
          <div class="ant-col ant-form-item-control">
            <div class="ant-form-item-control-input">
              <div class="ant-form-item-control-input-content">
                <div class="account-input">
                  <input v-model="username" type="text" class="ant-input i-input" placeholder="手机号"/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="ant-row ant-form-item">
          <div class="ant-col ant-form-item-control">
            <div class="ant-form-item-control-input">
              <div class="ant-form-item-control-input-content">
                <span class="ant-input-affix-wrapper ant-input-password i-input">
                    <input v-model="password" type="password" placeholder="密码" class="ant-input" autocomplete="new-password"/>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="ant-row ant-form-item" style="padding-top: 8px">
          <div class="ant-col ant-form-item-control">
            <div class="ant-form-item-control-input">
              <div class="ant-form-item-control-input-content">
                <div class="flex-between" style="font-size: 12px">
                  <label class="ant-checkbox-wrapper remember">
                    <span class="ant-checkbox">
                      <input v-model="isRemember" type="checkbox"/>
                    </span>
                    <span>记住密码</span>
                  </label>
                  <a @click="gotoForgetPassword" href="javascript:;" style="color: rgb(122, 128, 135)">忘记密码</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="ant-row ant-form-item" style="padding-top: 6px;margin-top: -16px;margin-bottom: 0px;">
          <div class="ant-col ant-form-item-control">
            <div class="ant-form-item-control-input">
              <div class="ant-form-item-control-input-content">
                <button @click="login" class="ant-btn i-button button-red" style="width: 100%">
                  <span>登 录</span>
                </button>
              </div>
            </div>
          </div>
        </div>

      <div style="text-align: center; height: 68px">
        <span @click="gotoLoginCode" class="login-content-to-account">验证登录</span>
      </div>
      <div class="auths-box">
        <div class="flex-center auths">
          <img @click="getWechatQrcodeUrl" src="../../assets/static/wechat.0137e3cd.svg">
        </div>
        <div style="font-size: 12px; text-align: center">
          点击登录，即表示同意<router-link tag="a" target="_blank" :to="{path:'/userAgreement'}">《用户协议》</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {setUserInfo,getCacheData,removeCacheData} from '@/utils';

export default {
  name: "login",
  data() {
    return {
       username: '',
       password: '',
       isRemember: false,
       cacheKey: 'WEB_ACCOUNT'
    }
  },
  created(){
    let account = getCacheData(this.cacheKey);
    if(account){
        this.username = account.accountName;
        this.password = account.accountPassword;
        this.isRemember = true;
    }
  },
  methods: {
    login(){
       this.$http.post('/api/login',{username: this.username,password: this.password}).then(data=>{
          let backName = this.$route.query.backName || 'account';
          setUserInfo(data);
          this.$router.push({name: backName});
          if(this.isRemember){
             getCacheData(this.cacheKey,{
                accountName: this.username,
                accountPassword: this.password
             });
          }else {
             removeCacheData(this.cacheKey);
          }
       });
    },
    gotoLoginCode(){
       this.$router.push({name: 'loginCode'});
    },
    gotoRegister(){
       this.$router.push({name: 'register'});
    },
    gotoForgetPassword(){
       this.$router.push({name: 'forgetPassword'});
    },
    getWechatQrcodeUrl(){
       this.$http.get('/api/common/getWechatQrcodeUrl',{state: 'login'}).then(data=>{
          location.href = data;
       });
    }
  }
};
</script>
<style scoped>
.login {
  width: 100%;
  height: 100%;
  background: #f7f9fc;
}
</style>